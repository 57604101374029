<template>
  <Teleport to="#teleports">
    <Transition name="fade">
      <dialog
        v-if="isOpen"
        ref="dialog"
        class="calendar"
        @click="handleClickOutside"
        @cancel.prevent="handleClose"
      >
        <div class="calendar__content">
          <button class="calendar__button" @click="handleClose">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M8.53268 25.3327L6.66602 23.466L14.1327 15.9993L6.66602 8.53268L8.53268 6.66602L15.9993 14.1327L23.466 6.66602L25.3327 8.53268L17.866 15.9993L25.3327 23.466L23.466 25.3327L15.9993 17.866L8.53268 25.3327Z"
                fill="currentColor"
              />
            </svg>
          </button>
          <iframe :src="calendarUrl" class="calendar__iframe" />
        </div>
      </dialog>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll
} from 'body-scroll-lock-upgrade'
import { withBase } from 'ufo'
import { computed, nextTick, onUnmounted, ref, watch } from 'vue'

const BASE_URL = 'https://calendar.google.com/calendar/appointments/schedules'

const props = defineProps<{ isOpen: boolean; calendarId: string | null }>()
const calendarId =
  props.calendarId ||
  'AcZssZ30dLw6cAom71i0psoSC0fSm4_DkJjjea6zh_n1_ctUvuy4EAXEHWCvuVzC3qwv6GkTf-pVqnrk'

const calendarUrl = computed(() => withBase(calendarId, BASE_URL))

const dialog = ref<HTMLDialogElement | null>()

const handleModalToggle = (state: boolean) => {
  if (state) {
    nextTick(() => {
      disableBodyScroll(dialog.value!, { reserveScrollBarGap: true })
      dialog.value?.showModal()
    })
  } else {
    enableBodyScroll(dialog.value!)
    nextTick(() => {
      dialog.value?.close()
    })
  }
}

watch(() => props.isOpen, handleModalToggle)

const emit = defineEmits<{ (event: 'close'): void }>()

const handleClose = () => emit('close')
const handleClickOutside = (e: Event) =>
  e.currentTarget === e.target ? emit('close') : undefined

onUnmounted(clearAllBodyScrollLocks)
</script>

<style lang="scss">
.calendar {
  position: fixed;
  z-index: z-index(popup);
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;

  @include frame-content-container(large);
  padding-bottom: rem($container-padding);
  padding-top: calc(var(--content-offset, 0px) + rem($container-padding));

  &::backdrop {
    background-color: rgba(color(black), 10%);
  }

  &__content {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: color(white);
    border-radius: em(8px);
    overflow: clip;
  }

  &__iframe {
    width: 100%;
    height: 100%;
  }

  &__button {
    position: absolute;
    z-index: z-index(over);
    top: 25px;
    right: 16px;
    height: rem(32px);
    width: rem(32px);
    color: color(black);
    background-color: white;
    border: none;
    border-radius: 50%;
    outline: none;
    padding: 0;
    translate: -50%;
    cursor: pointer;

    & > svg {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
